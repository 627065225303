.cont{
	padding-top: 50px;
}
.content-wrapper img{
	max-width: 1440px !important;
	width:100% !important;
	min-width: 300px;
	object-fit: contain;
  }
  .parent-element .Carousel-root{
	width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  }
.secTitle {
    color: #64748B;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;

    text-transform: uppercase;
    line-height: 24px;
}
.statusLabel {
	display: inline-block;
	vertical-align: middle;
	border-radius: 40px;
	padding: 2px 8px 2px 7px;
	min-width: 95px;
	max-height: 26px;
	overflow: hidden;
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	text-transform: uppercase;
	position: relative;
    font-family: 'Lato', sans-serif;

}
.statusLabel div {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding-left: 14px;
}
.statusLabel div::before {
	content: ".";
	position: absolute;
	top: -12px;
	font-size: 35px;
	left: 0px;
}
.statusLabelFont{
	max-width: 549px;
}
.published{
	background:white;
}
.adopted{
    background: #D1FAE5;
   color: #059669;
   font-size: 14px;
   font-weight: 600;
   line-height: 22px;
 font-family: 'Lato', sans-serif;

}
.pending{
    background: #FFF2C3;
    color: #D47615;  
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
font-family: 'Lato', sans-serif;

}
.archived{
    background: #93ccea;
    color: #2e6ea7; 
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
font-family: 'Lato', sans-serif;
 
}
.disabled{
    background: #D5D5D5;
    color: #5B5B5B; 
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
font-family: 'Lato', sans-serif;
 
}
.fostered{
    background: #EBDAFF;
    color: #9175FA; 
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	font-family: 'Lato', sans-serif;

}
.rejected{
    background: #F8E0E0;
    color: #7C240A;  
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	font-family: 'Lato', sans-serif;

}
 .proInfo, .proInfo1 {
	display: flex;
	align-content: center;
	justify-content: space-between;
    flex-wrap: wrap;
    padding:32px 0;
    border-bottom: 1px solid #E2E8F0;
}
.btnCant {
    flex-wrap: wrap;
    padding:32px 0;
    border-bottom: 1px solid #E2E8F0;
	display: flex;
	align-content: center;
	gap: 14px;
}

.proInfo1{
	display: block;
}
.btnCant button{
    margin: 0 !important;
}
.proInfo{
	justify-content: flex-start;
}
.proDetailtable{
	margin-top: 14px;
}
.proDetailtable tr td {
	border-bottom: none !important;
	padding: 0px 0px 16px 0px;
    word-break: break-word;

	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
	font-family: 'Lato', sans-serif;

	color: #0F172A;
	vertical-align: top;
}
.proDetailtable tr td:first-child{
	color:#64748B;
	width: 251px !important;
}

.proInfo2 {
	display: flex;
	align-content: center;
	justify-content: flex-start;
	margin-top: 22px;
}
.profileImg {
	width: 32px;
	height: 32px;
	border-radius: 100px;
	overflow: hidden;
	margin-right: 8px;
}
.profileName {
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	color: #94A3B8;
	font-family: 'Lato', sans-serif;

	align-items: center;
	display: flex;
	vertical-align: middle;
}
.profileName span{
	color: #0F172A;
}
.profileName .profileLoaction{
	font-weight: 400;
	display: inline-block;
	vertical-align: middle;

}
.pdflink{
	display: block;
	color: #03B5AA !important;
	cursor: pointer;
	font-weight: 600;
}
.ersBtncant{
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
.statusBtn {
	position: relative;
	z-index: 10;
}
.statusBtn button{
	padding: 10px 16px 10px 12px !important;
}
.statusBtn.open button{
	background:rgb(3, 181, 170) !important;
	color: #fff !important;
}
.statusBtn.open button svg{
	transform: rotate(180deg) !important;
}

.btnDropdown {
	margin: 0;
	list-style: none;
	text-align: left;
	background: #fff;
	position: absolute;
	top: 100%;
	right: 0;
	box-shadow: 0px 6px 24px 6px rgba(0,0,0,0.1);
	width: 180px;
	padding: 10px;
	border-radius: 10px;
	margin-top: 2px;
	display: none;
}
.statusBtn.open .btnDropdown{
	display: block;
}
.btnDropdown li {
	padding: 10px 10px;
	line-height: normal;
	text-transform: capitalize;
	font-size: 16px;
	font-family: 'Lato', sans-serif;

	color: #0F172A;
	font-weight: 400;
	cursor: pointer;
}
.btnDropdown li:hover{
	color: #03B5AA;
}

.customPopup .MuiDialog-paper{
	border-radius: 8px 8px 32px 8px !important;
}
/* @media(max-width:1200px){
	.btnCant button{
		min-width: inherit !important;
	}	
} */
@media(max-width:959px){
	.btnCant, .proInfo, .proInfo1{
		justify-content: flex-start;
	}	
	.btnCant button {
		margin-right: 15px !important;
	}
}
@media(max-width:679px){
	.ersBtncant button{
		font-size: 14px !important;
		padding: 10px !important;
		margin-left: 0 !important;
		margin-right: 5px !important;
	}
	.ersBtncant button svg{
		font-size: 16px !important;
		margin-right: 7px !important;
	}
}
@media(max-width:600px){
	.ersBtncant{ text-align: left !important; margin-top: 10px !important;}
	.btnCant button {
		margin-bottom: 5px !important;
	}
	.proInfo > div {
		margin-bottom: 5px !important;
	}
	.statusLabelFont{
		max-width: unset;
	}
}
@media(max-width:600px){
.proDetailtable tr td:first-child{
	color:#64748B;
	width: 200px !important;
}
}